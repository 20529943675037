import { ChangeDetectionStrategy, Component, Inject, NgZone, OnInit, inject, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { filter, withLatestFrom, debounceTime, combineLatestWith, map, fromEvent, timer, BehaviorSubject, delay, firstValueFrom } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { IonContent, IonModal, ModalController } from '@ionic/angular/standalone';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatBadgeModule } from '@angular/material/badge';
import {
  AudioService,
  AuthService,
  ChatStateService,
  CheaseedStripeService,
  CheaseedUser,
  ContentService,
  EntryService,
  SharedChatService,
  SharedEventService,
  SharedUserService,
  CleverTapService,
  OpenAIDriverService,
  Events,
  PromptService,
  AirtableHelper
} from '@cheaseed/cheaseed-core';
import { LoginComponent } from '@cheaseed/portal/login';
import { PortalOfferService, PortalUtilityService } from '@cheaseed/portal/util';
import { CheaseedEnvironment, PromptStatusValue, YYYYMMDDHHmm_FORMAT, formatDate, retry } from '@cheaseed/node-utils';
import { PurchaseComponent, PurchaseStatsComponent } from '@cheaseed/portal/purchase';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GlobalMessageComponent } from '@cheaseed/shared/ui';
import { NetworkStatusService } from '@cheaseed/shared/util';

interface OptionItem {
  label?: string;
  labelfunc?: () => string;
  icon?: string;
  action?: (user: CheaseedUser) => void;
  displayIf?: (user: CheaseedUser) => boolean;
  enableIf?: (user: CheaseedUser) => boolean;
  externalLink?: string;
  top?: boolean;
  routerLink?: string;
  role?: string;
}

@Component({
  standalone: true,
  imports: [ 
    CommonModule, 
    RouterModule,
    LoginComponent,
    PurchaseComponent,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    MatBadgeModule,
    IonContent,
    IonModal,
    GlobalMessageComponent
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {

  auth = inject(AuthService)
  router = inject(Router)

  userService = inject(SharedUserService)
  contentService = inject(ContentService)
  conversationService = inject(SharedChatService)
  utilityService = inject(PortalUtilityService)
  private promptService = inject(PromptService)
  private audioService = inject(AudioService)
  private entryService = inject(EntryService)
  private eventService = inject(SharedEventService)
  private chatStateService = inject(ChatStateService)
  private stripeService = inject(CheaseedStripeService)
  private modalController = inject(ModalController)
  private networkStatusService = inject(NetworkStatusService)
  private clevertap = inject(CleverTapService)
  private portalOfferService = inject(PortalOfferService)
  private route = inject(ActivatedRoute)
  private openai = inject(OpenAIDriverService)
  private gtmService = inject(GoogleTagManagerService)
  airtableHelper = inject(AirtableHelper)

  networkStatusAlertOpen$ = new BehaviorSubject(false)

  // Cache latest status to control menu state
  private lastStatus?: PromptStatusValue

  authorizedOptions$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      combineLatestWith(this.userService.role$),
      map(([, role]) => {
        // console.log("authorizedOptions$ role", role)
        return this.menuOptions.filter(p => (p.role === 'admin' && role === 'admin') || !p.role)
      }),
      // tap(options => console.log("authorizedOptions$", options))
    )

  appInfo$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      combineLatestWith(this.contentService.contentConfigTimestamp$),
      map(([user, secs]) => {
        return {
          user,
          environment: this.environment,
          contentTimestamp: formatDate(secs * 1000, YYYYMMDDHHmm_FORMAT)
        }
      })
    )

  purchaseParam$ = this.route.queryParamMap.pipe(map(params => params.get('purchase')))
  purchaseLaunchCount$ = new BehaviorSubject(0)

  shouldShowSignIn$ = this.openai.promptStatus$
    .pipe(map(status => !status || status.status === 'NOTIFIED'))
  
  shouldSignOut$ = new BehaviorSubject(false)
  showHelp$ = new BehaviorSubject(false)

  syncProgress = signal('')

  menuOptions: OptionItem[] = [
    { label: 'Home', routerLink: '/home' },
    { labelfunc: () => this.contentService.getGlobal('portal.reports.title.message'), routerLink: '/reports', displayIf: (user) => !user.isAnonymousUser },
    { label: 'Groups', routerLink: '/groups', displayIf: (user) => !user.isAnonymousUser }, // role: 'admin' 
    { label: 'Buy', routerLink: '/home?purchase=true' },
    { label: 'Gift', externalLink: 'https://www.cheaseed.com/gift' },
    { label: 'Admin', routerLink: '/advanced', role: 'admin' },
  ]

  profileOptions: OptionItem[] = [
    { label: 'My Credits', icon: 'profile', action: (user) => this.launchProfile(user), displayIf: (user) => !user.isAnonymousUser },
    { label: 'Help', icon: 'help', action: (user) => this.showHelp$.next(true) },
    { label: 'Privacy Policy', icon: 'privacy', externalLink: 'https://www.cheaseed.com/privacypolicy', top: false },
    { label: 'Sign In', icon: 'login', action: (user) => this.requestLogin(user), displayIf: (user) => user.isAnonymousUser, enableIf: (user) => this.shouldShowSignIn(user) },
    { label: 'Sign Out', icon: 'logout', action: () => this.signOut(), displayIf: (user) => !user.isAnonymousUser, enableIf: (user) => this.shouldShowSignOut(user) },
    { labelfunc: () => `${this.airtableHelper.isLive() ? 'Disable' : 'Enable'} Airtable Sync`, 
      action: () => this.airtableHelper.isLive() ? this.airtableHelper.isLive.set(false) : this.syncAirtableData(), 
      displayIf: () => !this.environment.production,
      role: 'admin' 
    },
    { label: 'Refresh Airtable Sync', 
      action: () => this.syncAirtableData(), 
      displayIf: () => this.airtableHelper.isLive(), 
      role: 'admin' 
    }
  ]
  
  constructor(
    @Inject('environment') public environment: CheaseedEnvironment,
    private ngZone: NgZone
  ) { 
      this.auth.user$
        .pipe(
          debounceTime(300),
          takeUntilDestroyed())
        .subscribe(user => {
          const url = window.location.href
          // console.log('app auth.user$', user, url)
          // If no user or anonymous user
          if (!user) {
            // Check if email link
            if (this.auth.isSignInWithEmailLink(url))
              this.auth.processEmailLinkSignIn(url)
          }
          else if (user.isAnonymous) {
            if (this.auth.isSignInWithEmailLink(url))
              this.auth.processEmailLinkSignIn(url)
          }
        })

        this.shouldSignOut$
            .pipe(
                filter(signOut => !!signOut),
                takeUntilDestroyed())
            .subscribe(() => {
                console.log('actually signing out')
                this.auth.logout()
                this.shouldSignOut$.next(false)
            })

        this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                map((e:NavigationEnd) => e.url),
                withLatestFrom(this.userService.user$, this.purchaseParam$, this.purchaseLaunchCount$),
                takeUntilDestroyed())
            .subscribe(([ url, user, purchase, launchCount ]) => {
                // console.log("received NavigationEnd", { url, user, purchase, launchCount })
                this.gtmService.pushTag({ event: 'page', pageName: url })
                if (user)
                    this.eventService.recordPageNavigation({ page: url })
                if (purchase && (launchCount === 0)) {
                    this.launchPurchase()
                    this.purchaseLaunchCount$.next(launchCount + 1)
                }
            })

      this.conversationService.purchaseCompleted$
        .pipe(
          filter(data => !!data),
          withLatestFrom(this.userService.user$),
          takeUntilDestroyed()
        )
        .subscribe(async ([data, user]) => {
          console.log("received purchaseCompleted$", data, user)
          this.conversationService.purchaseCompleted$.next(null)
          const msg = this.contentService.getGlobal("portal.credit.consumed") || "We consumed one of your credits to continue this coach."
          const message = `${data.purchaseMessage} ${msg.replace("$type", this.contentService.getSingularGlobal(data.seedType))}`
          timer(1000).subscribe(() => this.launchPurchaseSummaryModal(user, message))
        })

      this.userService.paymentCompleted$
        .pipe(
          filter(data => !!data),          
          withLatestFrom(this.userService.user$, this.conversationService.nextConversation$),
          takeUntilDestroyed()
        )
        .subscribe(async ([data, user, conv]) => {
          console.log("received paymentCompleted$", data, user)
          this.userService.paymentCompleted$.next(null)
          this.dismissPurchase(false)
          if (!conv) {
            await this.utilityService.notify({ 
              header: 'Purchase Completed', 
              message: `<b>Added credits:</b> ${data}`})
          }
        })

      // Handle PortalOffer events (only for authenticated users)
      this.eventService.eventRecorded$
        .pipe(
          withLatestFrom(this.userService.user$),
          filter(([event, user]) => !!event && !!user && !user.isAnonymousUser),
          takeUntilDestroyed()
        )
        .subscribe(([event, user]) => {
          // console.log("received eventRecorded$", event, user)
          if ([ 
                Events.UserCreation,
                Events.GiftPurchased,
                Events.ConversationEnd].includes(event.name)) {
            this.portalOfferService.processOffer(user, event.name, event.attributes)
          }
        })

      this.openai.promptStatus$
        .pipe(takeUntilDestroyed())
        .subscribe(status => {
          this.lastStatus = status
        })

      this.contentService.nextReleaseAvailable$
        .pipe(
          filter(next => !!next),
          takeUntilDestroyed())
        .subscribe(async () => {
          console.log("about to reload app")
          this.contentService.nextReleaseAvailable$.next(false)
          await this.utilityService.notify({
            header: 'Update Available',
            backdropDismiss: false,
            message: 'An updated version of the application is available. Click OK to reload.',
            cancel: () => { 
              // No need to do this anymore, after fixing cache-control on server
              // window.location.href = window.location.href + '#' + Date.now()
              // console.log("reloading app at", window.location.href)
              window.location.reload() 
            }
            })
        })
  
      this.userService.devPasswordNeeded$
        .pipe(
          filter(needed => !!needed),
          withLatestFrom(this.userService.user$),
          takeUntilDestroyed())
        .subscribe(([ url, user]) => {
          // console.log("devPasswordNeeded$", user, url)
          this.utilityService.prompt({
            message: 'Enter dev portal password. ', 
            confirm: (prompt) => {
              if(!prompt || prompt.value !== this.environment.devPwd) {
                this.utilityService.notify({ message: 'Invalid Password' })
                return false // abort dismiss
              }
              this.userService.setUserAdminCertified(user)
              // console.log("dev password accepted, navigating to", url)
              this.ngZone.run(() => { this.router.navigateByUrl(url) })
              return true
            }})
        })
    }

  async requestLogin(user: CheaseedUser) {
    // console.log("requestLogin", user)
    if (!this.environment.production && !user.isAdminCertified) {
      this.userService.devPasswordNeeded$.next(true)
      return
    }
    this.userService.requestLogin$.next(true)
  }

  async ngOnInit() {

    console.log("App ngOnInit", { referrer: document.referrer, userAgent: navigator.userAgent })

    this.networkStatusService.onlineStatus$
        .pipe(withLatestFrom(this.networkStatusAlertOpen$))    
        .subscribe(async ([ status, open ]) => {
            console.log('networkStatus change detected', status, open)
            if (!status && !open) {
                this.networkStatusAlertOpen$.next(true)  
                await this.utilityService.notify({
                    header: 'No network connection',
                    message: 'Please check your network connection and try again.',
                    cancel: () => this.networkStatusAlertOpen$.next(false)
                })
            }
        }
    )

    // initialize clevertap web sdk
    this.clevertap.init(this.environment.clevertap.accountID, this.environment.clevertap.passCode)

    // Pre-load Globals from firestore
    await this.contentService.loadGlobalsFromFirestore()

    // Handle successful login
    this.userService.userLoadStatus$
      .pipe(
        withLatestFrom(this.userService.user$, this.contentService.loader$),
        filter(([status, user, ]) => !!status && !!user))
      .subscribe(([status, user, loaded]) => {
        if (['exists', 'created'].includes(status)) {
          console.log("App received userLoadStatus", status, user, loaded)
          this.initializeSession(status, user, loaded)
        }
      })

    fromEvent(window, 'visibilitychange')
      .subscribe(() => {
        if (document.visibilityState === "visible") {
          console.log("reactivating")
        } else {
          console.log("deactivating")
        }
      })   
  
  }

  clickProfile(user: CheaseedUser) {
    if (user.isAnonymousUser) {
      this.userService.requireLogin$.next(false)
      this.userService.requestLogin$.next(true)
    }
  }
  
  clickMenuItem(item: OptionItem, user: CheaseedUser) {
    // console.log("clickMenuItem", item)
    if (item.externalLink)
      window.open(item.externalLink, '_blank')
    else if (item.routerLink)
      this.router.navigateByUrl(item.routerLink)
    else if (item.action)
      item.action(user)
  }

  shouldShowSignIn(user: CheaseedUser) {
    return user.isAnonymousUser && (!this.lastStatus || this.lastStatus.status === 'NOTIFIED')
  }

  shouldShowSignOut(user: CheaseedUser) {
    return !user.isAnonymousUser && (!this.lastStatus || this.lastStatus.status === 'NOTIFIED')
  }

  async signOut() {
    if (this.auth.isLoggedIn()) {
      const url = this.router.url
      // console.log("signOut", url)
      if (url.includes('conversation')) {
        this.conversationService.setNextConversation(null)
      }
      else {
        await this.router.navigate(['/'])
      }
      this.shouldSignOut$.next(true)
      console.log("signed out")
    }
  }

  async initializeSession(status: string, user: CheaseedUser, contentLoaded = true) {

    // separate error handling for content loading so that it is easier
    // to track. retry 2 times in case of failure the first time around
    try {
      if (!contentLoaded) {
        console.log('initializeSession','Loading resources using 2 retries')
        await retry((flag: boolean) => this.contentService.reload(flag), [true], 2)
      }
      this.contentService.subscribeToContentConfig()
    }
    catch (err) {
      console.error(`Error loading content for user ${user}`, err)
      this.utilityService.notify({
        header: "Unable to load content",
        message: `${err.message}`,
        cancel: async () => {
          await this.signOut()
        }
      })
      throw err // pass to global error handler
    }

    // Check AirtableSync in dev only
    if (!this.environment.production) {
      const contentTimestamp = await firstValueFrom(this.contentService.contentConfigTimestamp$)
      this.refreshFromAirtableSyncLocalStorage(contentTimestamp)
    }

    try {
      this.audioService.initialize()
      this.entryService.reinitialize()
      if (status === 'created') {
        // Record user creation
        this.eventService.recordUserCreation(user)
        this.userService.isFirstVisitToday()
      }
      else
        this.eventService.setProfile({
          name: user.name,
          provider: user.provider,
          isInternalUser: !!this.userService.hasAdminClaim()
        })

      this.stripeService.initialize(this.modalController)

      this.chatStateService.initialize(user.docId) 

    }
    catch (err) {
      console.error(`Error initializing user ${user}`)
      this.utilityService.notify({
        header: "Unable to launch application",
        message: `${err.message}`,
        cancel: async () => {
          await this.signOut()
        }
      })
      throw err // pass to global error handler
    }
  }

  dismissAuth() {
    this.userService.requestLogin$.next(false)
  }

  dismissPurchase(routeToHome = false) {
    console.log("dismissPurchase")
    this.utilityService.willDismiss()
    this.userService.requestPayment$.next(undefined)
    this.conversationService.waitingForPurchase$.next(false)
    this.purchaseLaunchCount$.next(0)
    if (routeToHome) {
      console.log("dismissPurchase routeToHome current", this.router.url)
      this.router.navigateByUrl('/home', {onSameUrlNavigation: 'reload'})
    }
  }

  launchPurchase() {
    console.log("launchPurchase")
    this.userService.requestPayment$.next(true)
    this.conversationService.waitingForPurchase$.next(true)
  }

  async launchProfile(user: CheaseedUser) {
    await this.utilityService.launchModalComponent(PurchaseStatsComponent, 
      { 
        user,
        showPurchases: true
       }, 
      'purchase-modal')
  }

  async launchPurchaseSummaryModal(user: CheaseedUser, message: string) {
    await this.utilityService.launchModalComponent(PurchaseStatsComponent, 
      { 
        user,
        message,
        showPurchases: false
       }, 
      'purchase-modal')
  }

  async syncAirtableData() {
    console.log("syncAirtableData")
    try {
      this.syncProgress.set("Connecting to Airtable")
      await this.airtableHelper.loadSchema()

      this.syncProgress.set("Syncing Globals")
      const [ ,globals ] = await this.airtableHelper.getTableContents("Global")
      this.contentService.prepareGlobals(globals.map((g:any) => ({ name: g.Name, value: g.Value })))

      this.syncProgress.set("Syncing PromptSpecs")
      const [ ,promptSpecs ] = await this.airtableHelper.getTableContents("PromptSpec")
      this.promptService.updateFromAirtable(promptSpecs)

      this.syncProgress.set("Syncing AttributeSpecs")
      const [ ,attrSpecs ] = await this.airtableHelper.getTableContents("AttributeSpec")
      this.contentService.updateAttributeSpecsFromAirtable(attrSpecs)

      this.airtableHelper.setAirtableSyncTimestamp()
    }
    catch (err:any) {
      this.syncProgress.set(`Error: ${err.message}`)
      console.error(err)
      await delay(2000)
    }
    finally {
      this.syncProgress.set("")  
    }
  }

  refreshFromAirtableSyncLocalStorage(secs: number) {
    if (this.airtableHelper.isAirtableSyncMoreCurrentThan(secs * 1000)) {
      this.airtableHelper.restoreSchema()
      const globals = this.airtableHelper.getLocalStorage("Global")
      this.contentService.prepareGlobals(globals.map((g:any) => ({ name: g.Name, value: g.Value })))
      const promptSpecs = this.airtableHelper.getLocalStorage("PromptSpec")
      this.promptService.updateFromAirtable(promptSpecs)
      const attrSpecs = this.airtableHelper.getLocalStorage("AttributeSpec")
      this.contentService.updateAttributeSpecsFromAirtable(attrSpecs)
    }
  }
}
