import { Inject, Injectable, inject } from '@angular/core'
import { orderBy } from '@angular/fire/firestore';
import { CheaseedUser, ContentService, Events, FirebaseService, GroupService, SharedEventService, SharedUserService } from '@cheaseed/cheaseed-core';
import { filter, switchMap, map, tap, shareReplay, firstValueFrom, BehaviorSubject, combineLatest, debounceTime, combineLatestWith, withLatestFrom } from 'rxjs';
import { add, format } from "date-fns";
import { CheaseedEnvironment, MMDDYYYY_FORMAT, PortalOfferSpec, UserPortalOffer, UserPortalOfferConverter, todaystr } from '@cheaseed/node-utils';
import { PortalUtilityService } from './portal-utility.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PortalOfferService {

  private router = inject(Router)
  private firebaseService = inject(FirebaseService)
  private userService = inject(SharedUserService)
  private contentService = inject(ContentService)
  private utilityService = inject(PortalUtilityService)
  private eventService = inject(SharedEventService)
  private groupService = inject(GroupService)
  waitingForAuthentication$ = new BehaviorSubject<any>(null)

  portalOffers$ = this.contentService.portalOffers$
  userOffers$ = new BehaviorSubject<Map<string, UserPortalOffer>>(new Map())

  // User offers for the current user
  private queryUserOffers$ = this.userService.userLoggedIn$
    .pipe(
      filter(user => !user?.isAnonymousUser),
      // tap(user => console.log('about to query user portal offers', user?.docId)),
      switchMap(() => this.firebaseService.collection$(this.userService.getUserPortalOffersPath(), orderBy('createdAt', 'desc'))),
      debounceTime(300),
      map(offers => UserPortalOfferConverter.fromArray(offers)),
      map(offers => new Map<string, UserPortalOffer>(offers.map(offer => [ offer.name, offer ]))),
      tap(res => console.log(`retrieved user offers`, res)),
      shareReplay(1)
    )
  
  constructor(
    @Inject('environment') public environment: CheaseedEnvironment
  ) { 
      this.userService.userLoggedIn$
        .pipe(
          combineLatestWith(this.queryUserOffers$),
          takeUntilDestroyed()
        )
        .subscribe(([ user, offers ]) => {
          this.userOffers$.next(user?.isAnonymousUser ? new Map() : offers)
        })
      
      // Handle post-authentication activation
      combineLatest([ this.waitingForAuthentication$, this.userService.requestLogin$ ])
        .pipe(
          debounceTime(500),
          // tap(data => console.log('waiting for auth', data)),
          filter(([ waiting, requestLogin ]) => !!waiting && !requestLogin),
          withLatestFrom(this.userService.user$),
          takeUntilDestroyed()
        )
        .subscribe(data => {
          const [ [ waiting, ], user ] = data
          // Wait to activate an offer until after the user logs in
          // console.log('waiting to activate', { waiting, user })
          if (!user?.isAnonymousUser) {
            this.waitingForAuthentication$.next(null)
            this.activateOffer(user, waiting.spec)
          }
        })
  }  

  async processOffer(user: CheaseedUser, event:Events, params?: any) {
    const today = todaystr()
    const offers = await firstValueFrom(this.portalOffers$)
    // Find candidate offers that match the event and are not expired
    const candidates = offers.filter(offer => 
      offer.triggerEvent === event 
      && (offer.triggerEvent !== event || !offer.triggerConversation?.name || offer.triggerConversation.name === params.id)
      && (!offer.expirationDate || today <= offer.expirationDate))
    // Sort the candidate offers by specificity
    candidates.sort((a, b) => a.triggerConversation ? -1 : 1 )
    // Find the first most-specific offer that the user does not already have
    const userOffers = await firstValueFrom(this.userOffers$)

    // Get current group from one of two sources
    const group = this.groupService.checkingGroupToJoin() || this.groupService.currentUserGroup()
    // Check prepaid group balance
    if (this.groupService.isPrepaidWithBalance(group))
      console.log(`Skipping offer activation because group ${group?.name} is prepaid and has a valid balance`)
    else {
      for (const offer of candidates) {
        if (!userOffers.has(offer.name)) {
          // Add the offer to the user's portalOffers
          await this.addUserPortalOffer(offer)
          return
        }
      }
    }
  }

  getPortalOffer(code: string) {
    return this.portalOffers$
      .pipe(
        map(offers => offers.find(offer => offer.code === code)),
        tap(offer => console.log('portal offer', code, offer))
      )
  }
  
  getUserPortalOffer(code: string) {
    return this.userOffers$
      .pipe(
        map(userOffers => Array.from(userOffers.values()).find(offer => offer.code === code)),
        tap(offer => console.log('user offer', offer))
      )
  }

  readUserPortalOffer(code: string) {
    const path = this.userService.getUserPortalOffersPath()
    return this.userService.userLoggedIn$
      .pipe(
        switchMap(() => this.firebaseService.doc$(`${path}/${code}`)),
        debounceTime(300),
        map(offer => offer ? UserPortalOfferConverter.fromFirestoreData(offer) : null),
        tap(offer => console.log('read user offer', offer))
      )
  }

  getAvailableUserPortalOffers() {
    return this.userOffers$
      .pipe(
        // tap(userOffers => console.log('userOffers', userOffers)),
        map(userOffers => Array.from(userOffers.values()).filter(offer => !offer.redeemedAt && (offer.expiresAt as Date) >= new Date()))
      )
  }

  async checkActivateOffer(user: CheaseedUser | null, spec?: PortalOfferSpec) {
    console.log('checkActivateOffer', user, spec)
    if (user?.isAnonymousUser) {
      this.waitingForAuthentication$.next({ spec })
      this.userService.requestLogin$.next(true)
    }
    else
      await this.activateOffer(user, spec)
  }

  async activateOffer(user: CheaseedUser | null, spec?: PortalOfferSpec) {
    // Check if the user already has this offer
    const offer = await firstValueFrom(this.readUserPortalOffer(spec?.code as string))
    const group = this.groupService.currentUserGroup()
    console.log('activateOffer', { user, spec, offer, group })
    if (this.groupService.isPrepaidWithBalance(group)) {
      const message = this.contentService.getGlobal('portal.offer.activationNotAllowed.message')
        || `As long as your group continues to pay for your use, you cannot activate an offer.`
      await this.utilityService.notify({
        message,
        cancel: () => this.router.navigateByUrl(spec?.routeAfterActivation || '/home')
      })
    }
    else if (offer) {
      const msg = offer.redeemedAt
        ? `Offer ${offer.name} has already been redeemed in your account.`
        : `Offer ${offer.name} is already activated in your account.`
      await this.utilityService.presentToast(msg, { position: 'bottom', duration: 4000 })
      this.router.navigateByUrl(spec?.routeAfterActivation || '/home')
    }
    else if (!offer && user && !user?.isAnonymousUser) { // ensure user is not anonymous
      this.addUserPortalOffer(spec)
      this.router.navigateByUrl(spec?.routeAfterActivation || '/home')
    }
    // Clear the waitingForAuthentication$ subject to prevent reuse
    this.waitingForAuthentication$.next(null)
  }

  private async addUserPortalOffer(offer?: PortalOfferSpec) {
    const expDate = this.computeExpirationDate(offer)
    const path = this.userService.getUserPortalOffersPath() + (offer?.code ? `/${offer.code}` : '')
    await this.firebaseService.updateAt(path, {
      name: offer?.name,
      userId: this.userService.getCurrentUserId(),
      code: offer?.code,
      createdAt: new Date(),
      expiresAt: expDate,
      spec: offer
    })
    const expstr = format(expDate, MMDDYYYY_FORMAT)
    const msg = `Offer ${offer?.code || offer?.name} ($${offer?.value}) added to your account.\nExpires ${expstr}.`
    await this.utilityService.presentToast(msg, { duration: 5000 })
    this.eventService.record(Events.PortalOfferActivated, { 
      name: offer?.name, 
      code: offer?.code, 
      expirationDate: expstr
    })
  }

  async redeemUserPortalOffer(offer: UserPortalOffer | null) {
    if (offer) {
      const path = this.userService.getUserPortalOffersPath() + `/${offer.docId}`
      const redeemedAt = new Date()
      await this.firebaseService.updateAt(path, { redeemedAt })
      this.eventService.record(Events.PortalOfferRedeemed, { 
        name: offer?.name, 
        code: offer?.code, 
        redeemedAt: format(redeemedAt, MMDDYYYY_FORMAT)
      })
    }
  }

  computeExpirationDate(offer?: PortalOfferSpec) {
    if (offer?.expirationDate) {
      return new Date(offer.expirationDate)
    }
    else if (offer?.expiringIn) {
      // expiringIn is a string like '5d' or '2w' or '1m'
      const unit = offer.expiringIn.slice(-1)
      let value = parseInt(offer.expiringIn.slice(0, -1))
      const now = new Date()
      let expDate
      switch (unit) {
        case 'd': 
          expDate = add(now, { days: value })
          break;
        case 'w': 
          expDate = add(now, { weeks: value })
          break;
        case 'm': 
          expDate = add(now, { months: value })
          break;
        case 'y': 
          expDate = add(now, { years: value })
          break;
        default:
          value = parseInt(offer.expiringIn)
          expDate = add(now, { days: value })
          break;
      }
      // return formatDate(expDate, YYYYMMDD_FORMAT)
      return expDate
    }
    else {
      // return formatDate(add(new Date(), { days: 7 }), YYYYMMDD_FORMAT)
      return add(new Date(), { days: 7 })
    }
  }

}